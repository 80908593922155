/* ./src/index.css */
/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* font-family: 'Lato', sans-serif; */
  font-family: 'Cairo', sans-serif !important;
}
